import React from "react";

export const LiveStream = (props) => {
  return (
    <div id="about">
       <h3>Discovery Talent LiveStream starts April 20 7pm</h3>
      <div className="container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/live_stream?channel=UC0PD4GCYHcvfih7yZsa8swQ" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  );
};
